/* eslint-disable jsx-a11y/anchor-is-valid */
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  const { data } = decoded;
  const { isAdmin, permission: { product, user, order, support, order_manage, igm }, } = data;

  const style = {
    color: "#000"
  }

  const bgStyle: React.CSSProperties = {
    backgroundColor: "rgb(21 12 12)",
    // position: 'fixed',
    // left: 0,
    // zIndex: 99,
    // height: '100vh',
    // overflowY: 'auto',
    // overflowX: 'hidden',
  }

  // console.log("isAdmin", isAdmin)
  // console.log("data", data)
  return (
    <>
      <ul
        className="navbar-nav  sidebar sidebar-dark accordion"
        id="accordionSidebar" style={bgStyle} >
        <Link to="/">
          <span className="sidebar-brand d-flex align-items-center justify-content-center bg-white" >
            <div className="text-center">
              <img src="/img/pw.png" style={{ height: "35px" }} alt="logo here" />
            </div>
            {/* <div className="sidebar-brand-text" style={style}> ONDC <span style={{ color: "#CC1033" }}>REDTAPE</span></div> */}
          </span>
        </Link>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item active">
          <Link to="/">
            <span className="nav-link">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </span>
          </Link>
        </li>

        {/* { isAdmin === "true" ? ( */}
        {
          isAdmin === "true" || product?.write === true || product?.read === true ? (
            <>
              <hr className="sidebar-divider" />
              <div className="sidebar-heading">Interface</div>
              <li className="nav-item">
                <Link to="#">
                  <a
                    className="nav-link collapsed"
                    href="replace"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Products</span>
                  </a>
                </Link>
                <div id="collapseTwo" className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar">
                  <div className="bg-white py-2 collapse-inner rounded">
                    {isAdmin === "true" ? (
                      <Link to="/addproduct">
                        <span className="collapse-item">
                          Add product
                        </span>
                      </Link>
                    ) : product.write ? (
                      <Link to="/addproduct">
                        <span className="collapse-item">
                          Add product
                        </span>
                      </Link>
                    ) : null}
                    {isAdmin === "true" ? (
                      <Link to="/productlist">
                        <span className="collapse-item">
                          Product List
                        </span>
                      </Link>
                    ) : product?.read ? (
                      <Link to="/productlist">
                        <span className="collapse-item">
                          Product List
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </li>
            </>
          ) : null}

        {isAdmin === "true" || order?.write === true || order?.read === true ? (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Order</div>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseOrder"
                  aria-expanded="true"
                  aria-controls="collapseOrder"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Orders</span>
                </a>
              </Link>
              <div id="collapseOrder"
                className="collapse"
                aria-labelledby="headingOrder"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {isAdmin === "true" ? (
                    <Link to="/orderlist">
                      <span className="collapse-item">
                        Orders List
                      </span>
                    </Link>
                  ) : order?.read ? (
                    <Link to="/orderlist">
                      <span className="collapse-item">
                        Orders List
                      </span>
                    </Link>
                  ) : null}
                </div>
              </div>
            </li>
          </>

        ) : null}


        {isAdmin === "true" || order_manage?.read === true ? (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Order Management</div>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseOrderManage"
                  aria-expanded="true"
                  aria-controls="collapseOrderManage"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Manage Orders</span>
                </a>
              </Link>
              <div
                id="collapseOrderManage"
                className="collapse"
                aria-labelledby="headingOrderManage"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {order_manage?.read === true ? (
                    <>
                      <Link to="/manage_orders">
                        <span className="collapse-item">
                          Orders List
                        </span>
                      </Link>
                      <Link to="/cancelreturn">
                        <span className="collapse-item">
                          Cancel & Return
                        </span>
                      </Link>
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          </>

        ) : order_manage?.read ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseOrderManage"
                  aria-expanded="true"
                  aria-controls="collapseOrderManage"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Manage Orders</span>
                </a>
              </Link>
              <div
                id="collapseOrderManage"
                className="collapse"
                aria-labelledby="headingOrderManage"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {order_manage?.read === true ? (
                    <>
                      <Link to="/manage_orders">
                        <span className="collapse-item">
                          Orders List
                        </span>
                      </Link>
                      <Link to="/cancelreturn">
                        <span className="collapse-item">
                          Cancel & Return
                        </span>
                      </Link>
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : null}
        {/* { isAdmin === "true" ? ( */}
        {/* new user management */}
        {isAdmin === "true" || user?.read === true || user?.write === true || user?.update === true || user?.delete === true ? (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">User Management</div>
            <li className="nav-item">
              <Link to="#" className="nav-link collapsed"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>Users</span>
              </Link>
              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {isAdmin === "true" ? (
                    <Link to="/adduser">
                      <span className="collapse-item">
                        Add user
                      </span>
                    </Link>
                  ) : user?.write ? (
                    <Link to="/adduser">
                      <span className="collapse-item">
                        Add user
                      </span>
                    </Link>
                  ) : null}
                  {isAdmin === "true" ? (
                    <Link to="/userlist">
                      <span className="collapse-item">
                        User List
                      </span>
                    </Link>
                  ) : user?.read ? (
                    <Link to="/userlist">
                      <span className="collapse-item">User List</span>
                    </Link>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : (
          <></>
        )}

        {/* Old user Management */}
        {/* <hr className="sidebar-divider" />
        <div className="sidebar-heading">UserManagement</div>
        <li className="nav-item">
          <Link
            to="#"
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>Users</span>
          </Link>
          <div
            id="collapseOne"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <Link to="/adduser">
                  <span className="collapse-item">
                    Add user
                  </span>
                </Link>
              ) : user.write ? (
                <Link to="/adduser">
                  <span className="collapse-item">
                    Add user
                  </span>
                </Link>
              ) : null}
              {isAdmin === "true" ? (
                <Link to="/userlist">
                  <span className="collapse-item">
                    User List
                  </span>
                </Link>
              ) : user?.read ? (
                <Link to="/userlist">
                  <span className="collapse-item">User List</span>
                </Link>
              ) : null}
            </div>
          </div>
        </li> */}

        {/* New search */}
        {isAdmin === "true" ? (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Search</div>
            <li className="nav-item">
              <Link
                to="#"
                className="nav-link collapsed"
                data-toggle="collapse"
                data-target="#collapsetwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>Search</span>
              </Link>
              <div
                id="collapsetwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link to="/searchkeyword">
                    <span className="collapse-item">
                      Search Keyword
                    </span>
                  </Link>

                  <Link to="/searchlog">
                    <span className="collapse-item">
                      Search Logs
                    </span>
                  </Link>
                </div>
              </div>
            </li>
          </>
        ) : (
          <></>
        )}


        {/* Old search */}
        {/* <hr className="sidebar-divider" />
        <div className="sidebar-heading">Search</div>
        <li className="nav-item">
          <Link
            to="#"
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapsetwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>Search</span>
          </Link>
          <div
            id="collapsetwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin == "true" ? (
                <>
                  <Link to="/searchkeyword">
                    <span className="collapse-item">
                      Search Keyword
                    </span>
                  </Link>

                  <Link to="/searchlog">
                    <span className="collapse-item">
                      Search Logs
                    </span>
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </li> */}

        {/*  SupportListing */}
        {/* {isAdmin === "true" || support?.read === true ? (
          <>
            <hr className="sidebar-divider d-none d-md-block" />
            <div className="sidebar-heading">Query</div>

            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapsefour"
                  aria-expanded="true"
                  aria-controls="collapsefour"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Support</span>
                </a>
              </Link>
              <div
                id="collapsefour"
                className="collapse"
                aria-labelledby="headingfour"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">

                  <Link to="/supportlist">
                    <span className="collapse-item">
                      Support List
                    </span>
                  </Link>
                </div>
              </div>
            </li>
          </>
        ) : (
          <></>
        )} */}


        {/* IGM */}
        {isAdmin === "true" || igm?.read === true || support?.read === true ? (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Grievance Management</div>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseIgm"
                  aria-expanded="true"
                  aria-controls="collapseIgm"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>IGM Portal</span>
                </a>
              </Link>
              <div
                id="collapseIgm"
                className="collapse"
                aria-labelledby="headingOrderManage"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">

                  {isAdmin === "true" ? (
                    <Link to="/issueslist">
                      <span className="collapse-item">
                        IGM
                      </span>
                    </Link>
                  ) : igm?.read === true ? (
                    <Link to="/issueslist">
                      <span className="collapse-item">
                        IGM
                      </span>
                    </Link>
                  ) : null}
                  {isAdmin === "true" ? (
                    <Link to="/supportlist">
                      <span className="collapse-item">
                      Support List
                      </span>
                    </Link>
                  ) : support?.read === true ? (
                    <Link to="/supportlist">
                      <span className="collapse-item">
                        Support List
                      </span>
                    </Link>
                  ) : null}

                  {/* {igm?.read === true ? (
                    <>
                      <Link to="/issueslist">
                        <span className="collapse-item">
                          IGM
                        </span>
                      </Link>
                      <Link to="/supportlist">
                        <span className="collapse-item">
                          Support List
                        </span>
                      </Link>
                    </>
                  ) : null} */}
                </div>
              </div>
            </li>
          </>
        ) : null}


        {/*  */}
        {/*  SupportListing */}
        <hr className="sidebar-divider d-none d-md-block" />
        <li className="nav-item">
          <Link to="#">
            <a className="nav-link collapsed" data-toggle="collapse" data-target="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
              <i className="fas fa-fw fa-cog"></i>
              <span>Txn_Id Search</span>
            </a>
          </Link>
          <div
            id="collapsefive"
            className="collapse"
            aria-labelledby="headingfour"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <Link to="/search_txnlog">
                  <a className="collapse-item">Search By Txnid</a>
                </Link>
              ) : order?.read ? (
                <Link to="/search_txnlog">
                  <a className="collapse-item">Search By Txnid </a>
                </Link>
              ) : null}
            </div>
          </div>

        </li>
        {/* txnid */}

      </ul >
    </>
  );

};

export default Sidebar;
