/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, SetStateAction } from "react";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { useCSVReader } from 'react-papaparse';
import jwt_decode from "jwt-decode";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import styles from "./styles.module.css";
import Bankdetails from "./BankDetails";
import PersonalDetails from "./PersonalDetails";
import config from "../../config/config";
import { Multiselect } from "multiselect-react-dropdown";
import aws from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';


const index = () => {
    const { baseUrl } = config;
    const [showThank, setshowThank] = useState(false);
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data: any } = decoded;

    let params = useParams();
    const { id }: any = params;
    const [pageNamem, setpageName] = useState(true);
    const [loading, setLoading] = useState(false);
    const [uploading, setUpLoading] = useState(false);
    const [roleData, setRoleData] = useState([]);
    const [daysName, setdaysName] = useState("");

    function tabredirect() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tab = urlParams.get('tab');
        return tab;
    }

    const [renderComponent, setrenderComponent] = useState(tabredirect() || 'defaultTab')

    const rawData = {
        opening_Time: '10:00',
        closing_days: "",
        opening_days: "",
        closing_Time: '19:00',
        incorporation_date: "",
        image: "",
        commission: 1,
        maximum_commission: "",
        fssai_lisence_number: "",
        seller_commission: 1,
        product_returnable: "false",
        product_replaceable: "false",
        customer_care_contact: "",
        product_cancelable: "",
        return_window: "",
        seller_pickup_return: "false",
        time_to_ship: "",
        cod: "false",
        delivery_charges: "",
        packing_charges: "",
        sell_on_ondc: "",
        // status: "true",
        // approved_status: "0"
    };
    const errorRawData = {
        opening_Time: false,
        closing_days: false,
        opening_days: false,
        closing_Time: false,
        incorporation_date: false,
        image: false,
        commission: false,
        maximum_commission: false,
        fssai_lisence_number: false,
        seller_commission: false,
        product_returnable: false,
        product_replaceable: false,
        customer_care_contact: false,
        product_cancelable: false,
        return_window: false,
        seller_pickup_return: false,
        time_to_ship: false,
        cod: false,
        // status: false,
        sell_on_ondc: false
    };
    const [userData, setUserData] = useState(rawData);
    const [userDataError, setUserDataError]: any = useState(errorRawData);
    const timeToShipLength = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    //code for the days selection:-

    const [Oweekdays, setOweekdays] = useState([
        { day: "Monday", id: "1" }, { day: "Tuesday", id: "2" }, { day: "Wednesday", id: "3" }, { day: "Thursday", id: "4" }, { day: "Friday", id: "5" }, { day: "Saturday", id: "6" }, { day: "Sunday", id: "7" },
    ])
    const [Cweekdays, setCweekdays] = useState([
        { cday: "Monday", id: "1" }, { cday: "Tuesday", id: "2" }, { cday: "Wednesday", id: "3" }, { cday: "Thursday", id: "4" }, { cday: "Friday", id: "5" }, { cday: "Saturday", id: "6" }, { cday: "Sunday", id: "7" },
    ])
    const [selectedCDays, setSelectedCDays]: any = useState();
    const [selectedODays, setSelectedODays]: any = useState(Oweekdays);
    //code for the days selection end:-

    const inputHandler = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const errorHandler = (e: any) => {
        if (e.target.value) {
            setUserDataError({ ...userDataError, [e.target.name]: false });
        } else {
            setUserDataError({ ...userDataError, [e.target.name]: true });
        }
    }
    const getRole = async () => {
        try {
            const { data } = await axios({
                url: `${baseUrl}/user/getshopdetail`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            if (data?.data.length !== 0) {
                setUserData(data?.data[0]);
            }
            setUserData(data?.data[0]);
            setpageName(false);
            const openingDays = data?.data[0]?.opening_days?.split(",").map((day: string) => day.trim());
            const closingDays = data?.data[0]?.closing_days?.split(",").map((day: string) => day.trim());
            const filteredCDays = Cweekdays?.filter((day) => closingDays?.includes(day.id)) || [];
            const filteredODays = Oweekdays?.filter((day) => openingDays?.includes(day.id)) || [];
            setSelectedCDays(filteredCDays);
            setSelectedODays(filteredODays);
            // }
        } catch (error) {
            console.log("error", error)
        }
    };
    const submitSellerDetailsHandler = async () => {
        setLoading(true);
        try {
            const {
                opening_days,
                opening_Time,
                closing_days,
                closing_Time,
                incorporation_date,
                image,
                commission,
                maximum_commission,
                fssai_lisence_number,
                seller_commission,
                product_returnable,
                product_replaceable,
                customer_care_contact,
                product_cancelable,
                return_window,
                seller_pickup_return,
                time_to_ship,
                cod,
                // status,
                sell_on_ondc
            } = userData;
            // 
            if (opening_days && opening_Time && closing_days && closing_Time && product_returnable
                !== "" && product_replaceable !== "" && customer_care_contact && image !== "" && product_cancelable !== "" &&
                return_window !== "" && seller_pickup_return !== "" && time_to_ship !== "" && cod !== "") {

                const { data } = await axios({
                    url: `${baseUrl}/user/shopdetail`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: userData,
                });
                toast.success("Details submitted Successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    pageChangeController()

                }, 3000)
                // if (data.status === 200) {
                //     toast.success("Details submitted Successfully", {
                //         position: "top-right",
                //         autoClose: 2000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     });
                //     setUserData(data.data[0]);
                //     setTimeout(() => {
                //         pageChangeController()
                //     }, 3000)

                // }
            } else {
                toast.error("Please fill all the fields !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const onSelectHandler = (selectedList: any[], selectedItem: any) => {
        debugger
        let daySelected = selectedList.map((item: { id: any; }) => item.id);
        let z = daySelected.toString();
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map((item: any) => item));
    }
    const onRemoveHandler = (selectedList: any[], selectedItem: any) => {
        debugger
        let daySelected = selectedList.map((item: { id: any; }) => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map((item: any) => item));
    }

    const productImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `image/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            console.log(Location);
            setUserData({ ...userData, [e.target.name]: Location })
        }
        catch (exception) {
            console.log(exception);
        }
    };
    function renderController(e: any) {
        setrenderComponent(e.target.value)
    }

    function pageChangeController() {
        if (renderComponent === "shopdetails") {
            setrenderComponent("personaldetails")
        }
        if (renderComponent === "personaldetails") {
            setrenderComponent("bankdetails")
        }
    }

    useEffect(() => {
        getRole();
        if (localStorage.getItem("showthank")) {
            setshowThank(true)
        }
    }, []);

    // console.log("userData", userData)
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header p-0">
                                {showThank && <div className={"styles.thanks_container"} style={{ background: "white" }}>
                                    <div className={"styles.thanks_statement"}>
                                        <p>Thank you for registering with Seller Portal</p>
                                        <p className='m-1' style={{ fontSize: "13px", background: "white", marginBottom: "12px" }}>Your request is under process, your outletwill be live once request is approved.</p>
                                        <p className='m-1' style={{ fontSize: "13px", background: "white", marginBottom: "12px" }}>In mean time please confirm shop details.</p>
                                    </div>
                                </div>}
                                <h6 className="m-0 font-weight-bold text-primary">
                                    {/* {pageNamem ? "Add Shop Details" : "Update Details"} */}
                                    <div className={styles.tabs}>
                                        <button onClick={renderController} value="shopdetails" className={renderComponent === "shopdetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Shop Details
                                        </button>
                                        <button onClick={renderController} value="personaldetails" className={renderComponent === "personaldetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Personal   Details</button>
                                        <button onClick={renderController} value="bankdetails" className={renderComponent === "bankdetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Bank Details</button>

                                    </div>
                                </h6>
                            </div>
                            {renderComponent === "shopdetails" ? <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Opening Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.opening_Time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                name="opening_Time"
                                                className="form-control"
                                                placeholder="Enter Closing Time"
                                                value={userData?.opening_Time}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Closing Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.closing_Time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                name="closing_Time"
                                                className="form-control"
                                                placeholder="Enter Closing Time"
                                                value={userData.closing_Time}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Incorporation Date
                                                {/* <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.incorporation_date ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                                            </label>
                                            <input
                                                type="date"
                                                name="incorporation_date"
                                                className="form-control"
                                                placeholder="Enter Date"
                                                value={userData.incorporation_date}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Image
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.image ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            {userData.image && <a href={userData.image} target="_blank" style={{ fontSize: "10px", marginLeft: "3px", textDecoration: "underline" }} rel="noreferrer">View Document</a>}
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => productImageHandler(e)}
                                                accept="image/*"
                                                name="image"
                                                style={{ lineHeight: "1" }}
                                                multiple
                                                onBlur={(e) => errorHandler(e)}
                                                id="image"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Maximum  Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.maximum_commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="maximum_commission"
                                                className="form-control"
                                                value={userData.maximum_commission}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Max Commission"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label>Seller Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.seller_commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="seller_commission"
                                                className="form-control"
                                                value="1"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter ONDC Commission"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label>Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="commission"
                                                className="form-control"
                                                placeholder="Enter Commission"
                                                value="1"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Fssai License Number
                                                {/* <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.fssai_lisence_number ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                                            </label>
                                            <input
                                                type="text"
                                                maxLength={14}
                                                name="fssai_lisence_number"
                                                className="form-control"
                                                value={userData.fssai_lisence_number}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter License Number"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product returnable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.product_returnable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.product_returnable}
                                                name="product_returnable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select returnable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Sell On ONDC</label>
                                            {/* <span className="text-danger" > * </span>
                                            {
                                                userDataError?.sell_on_ondc ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            <select
                                                className="form-control"
                                                value={userData.sell_on_ondc}
                                                name="sell_on_ondc"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Sell on ONDC</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Replaceable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.product_replaceable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.product_replaceable}
                                                name="product_replaceable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Replaceable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Cancelable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.product_cancelable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.product_cancelable}
                                                name="product_cancelable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Cancelable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Customer Care Contact
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.customer_care_contact ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="customer_care_contact"
                                                className="form-control"
                                                value={userData.customer_care_contact}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Customer Care Number"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Cash On delivery</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.cod ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.cod}
                                                name="cod"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select cash on delivery</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Delivery Charges

                                            </label>
                                            <input
                                                type="text"
                                                name="delivery_charges"
                                                className="form-control"
                                                value={userData.delivery_charges}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Delivery Charges"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Packing Charges

                                            </label>
                                            <input
                                                type="text"
                                                name="packing_charges"
                                                className="form-control"
                                                value={userData.packing_charges}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Packing Charges"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Seller pickup return</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.seller_pickup_return ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.seller_pickup_return}
                                                name="seller_pickup_return"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select seller return pickup</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Return Window</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.return_window ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.return_window}
                                                name="return_window"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Return Window</option>
                                                {timeToShipLength.map((item) => (
                                                    <option value={`P${item}D`}>{`${item}Days`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Time to ship</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.time_to_ship ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.time_to_ship}
                                                name="time_to_ship"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Time to Ship</option>
                                                {timeToShipLength.map((item) => (
                                                    <option value={`P${item}D`}>{`${item}Days`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Closing Days
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.closing_days !== "valid" && userDataError?.closing_days !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <Multiselect options={Cweekdays} selectedValues={selectedCDays} displayValue="cday" onSelect={(selectedList: { map: (arg0: { (item: any): any; (item: any): any; }) => SetStateAction<undefined>; }, selectedItem: any) => {
                                                let daySelected: any = selectedList.map((item: { id: any; }) => item.id)
                                                let z = daySelected.toString()
                                                setUserData({ ...userData, closing_days: z });
                                                setSelectedCDays(selectedList.map((item: any) => item));
                                            }}></Multiselect>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                <label>Opening Days
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        userDataError?.opening_days !== "valid" && userDataError?.opening_days !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </label>
                                            </label>
                                            <Multiselect options={Oweekdays} selectedValues={selectedODays} displayValue="day" onSelect={onSelectHandler} onRemove={onRemoveHandler}></Multiselect>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex justify-content-end">
                                        {pageNamem ? <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "saving..." : "Save"}
                                        </button> : <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "saving..." : "Update"}
                                        </button>}
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>
                            </div> : renderComponent === "personaldetails" ? <PersonalDetails pageChangeController={pageChangeController} /> : renderComponent === "bankdetails" && <Bankdetails />}
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default index;

// function uuidv4() {
//     throw new Error("Function not implemented.");
// }


// function givedta() {
//     throw new Error("Function not implemented.");
// }

