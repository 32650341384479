import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


interface Column {
    id: 'variant' | 'price' | 'quantity' | 'sku';
    label: string;
    minWidth?: number;
    align?: 'center';
    format?: (value: number) => string;
}



interface Data {
    variant: string;
    price: string;
    quantity: number;
    sku: number;

}

function createData(
    variant: string,
    price: string,
    quantity: number,
    sku: number,
): Data {

    return { variant, price, quantity, sku };
}


const columns: readonly Column[] = [
    { id: 'variant', label: 'Variant', minWidth: 170, align: 'center', },
    { id: 'price', label: 'Price', minWidth: 170, align: 'center', },
    {
        id: 'quantity',
        label: 'Quantity',
        minWidth: 170,
        align: 'center',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'sku',
        label: 'SKU',
        minWidth: 170,
        align: 'center',
        format: (value: number) => value.toLocaleString('en-US'),
    }
];




export default function VairantTables({ dataSet }: any) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <div className='mt-3'>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        
                        {(dataSet.size.length > 0 || dataSet.color.length > 0) ? <TableHead >
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead> : null}


                        {(dataSet.size.length > 0 && dataSet.color.length > 0) && <TableBody>
                            {dataSet?.size?.map((size: string) => {
                                return (
                                    dataSet?.color?.map((color: string) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} >

                                            <TableCell align='center'>
                                                {`${size}/${color}`}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <input type="text" className='form-control ' />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <input type="text" className='form-control ' />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <input type="text" className='form-control ' />
                                            </TableCell>



                                        </TableRow>
                                    ))

                                );
                            })}

                        </TableBody>}


                        {(dataSet.size.length > 0 && dataSet.color.length === 0) && <TableBody>
                            {dataSet?.size?.map((size: string) => {
                                return <TableRow hover role="checkbox" tabIndex={-1} >

                                    <TableCell align='center'>
                                        {`${size}`}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <input type="text" className='form-control ' />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <input type="text" className='form-control ' />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <input type="text" className='form-control ' />
                                    </TableCell>



                                </TableRow>
                            })}

                        </TableBody>}


                        {(dataSet.size.length === 0 && dataSet.color.length > 0) && <TableBody>
                            {dataSet?.color?.map((size: string) => {
                                return <TableRow hover role="checkbox" tabIndex={-1} >

                                    <TableCell align='center'>
                                        {`${size}`}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <input type="text" className='form-control ' />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <input type="text" className='form-control ' />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <input type="text" className='form-control ' />
                                    </TableCell>



                                </TableRow>
                            })}

                        </TableBody>}


                    </Table>
                </TableContainer>

            </Paper>
        </div>
    )
}
