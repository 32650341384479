import React, { CSSProperties, useState } from 'react'
import VairantTables from './VairantTables';

const styles = {
    optionStyle: {
        background: "lightgray",
        width: "50px",
        borderRadius: "20px"
    } as CSSProperties,

    addIcon: {
        background: "#a01115",
        // width: "100px",
        borderRadius: "20px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
    } as CSSProperties,

};

export default function Variants() {

    const [optionValues, setoptionValues] = useState([0]);
    const [inputValues, setinputValues] = useState({})
    const [variatType, setVariantType]: any = useState([])
    const [dataSet, setdataSet]: any = useState({
        size: [],
        color: []
    })
    function addHandler() {
        setoptionValues((count: any) => [...count, count[count.length - 1] + 1])
    }

    function inputChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setinputValues({ ...inputValues, [e.target.name]: e.target.value })

    }
    // console.log(variatType)
    function onDoneHandler() {
        let k = Object.values(inputValues)
        let z: any = { ...dataSet, size: [...k] }
        console.log(z)
        setdataSet({ ...z })
        setinputValues({})
        setoptionValues([0])
    }
    function onColorDoneHandler() {
        let k = Object.values(inputValues)
        let z: any = { ...dataSet, color: [...k] }
        console.log(z)
        setdataSet({ ...z })
        setinputValues({})
        setoptionValues([0])
    }

    return (
        <>
            < div className="" style={{ background: "white", minHeight: "100px" }} >

                {/* <p>Variants</p> */}

                <div>
                    {
                        dataSet.size.length > 0 && <div> <p>Size</p>
                            <p>
                                {
                                    dataSet.size.map((item: any, index: any) => (
                                        <span className='badge badge-primary mx-1' style={styles?.optionStyle} key={index}>{item}</span>
                                    ))
                                }
                            </p>
                        </div>
                    }
                </div>
                <div>
                    {
                        dataSet.color.length > 0 && <div> <p>Color</p>
                            <p>
                                {
                                    dataSet.color.map((item: any, index: any) => (
                                        <span className='badge badge-primary mx-1' style={styles?.optionStyle} key={index}>{item}</span>
                                    ))
                                }
                            </p>
                        </div>
                    }
                </div>

                <hr className="sidebar-divider" />

                <div>
                    <div className="p-2">
                        <div className='w-50 mt-3'>
                            <label htmlFor="">Option Name</label>
                            <select name="" id="" className='form-control' onChange={(e) => setVariantType(e.target.value)}>
                                <option value="">Select Variant Type</option>
                                <option value="size">Size</option>
                                <option value="color">Colour</option>
                            </select>
                        </div>

                        <div className='w-50 mt-3'>
                            <label htmlFor="">Option Values</label>
                            {
                                optionValues.map((item, index) => {
                                    return <div className='mt-2'>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <input type="text" className="form-control" key={index} name={`value${index}`} onChange={inputChangeHandler} />
                                            {optionValues[optionValues.length - 1] === index && <span className='badge badge-primary' style={styles?.addIcon}
                                                onClick={addHandler}><i className="fa fa-plus" aria-hidden="true"></i></span>}

                                        </div>

                                    </div>
                                })
                            }

                            {
                                variatType === "size" ? <button className='btn  mt-3' onClick={onDoneHandler} style={{ background: "#105293", color: "white", width: "100px", borderRadius: "20px" }}>Done</button> : null
                            }
                            {
                                variatType === "color" ? <button className='btn mt-3' onClick={onColorDoneHandler} style={{ background: "#105293", color: "white", width: "100px", borderRadius: "20px" }}>Done</button> : null
                            }

                        </div>
                    </div>

                </div>


                {/* listing of variants */}
                {/* <div className='mt-2' style={{maxHeight: "300px", overflow: "scroll"}}>
                    {
                        dataSet.size.map((item: any) => (
                            dataSet.color.map((col: any) => (
                                <p>{`${item}/${col}`}</p>
                            ))

                        ))
                    }
                </div> */}

                <VairantTables dataSet={dataSet} ></VairantTables>
            </div>

        </>
    )
}
