/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
// import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import ThemeMaker from "../../components/thememaker";
// import { json } from "stream/consumers";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';
import { Paginated } from "../../components/Paginated";
import Loader from "../../components/tabelloader";
import config from "../../config/config";
var $ = require("jquery");


// const myStatus: any = {
//   2: "Open",
//   3: "Pending",
//   4: "Resolved",
//   5: "Closed"
// }
const SupportList = () => {
  const { baseUrl } = config;

  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  // const { user, product, order, support } = decoded.data.permission;
  // const history = useHistory();
  const [supportList, setSupportList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setloading] = useState(true);
  // const closeModel: any = useRef();
  const [error, setError] = useState("");
  // const [_id, setId] = useState("");
  const [issueActions, setissueActions] = useState([])
  // filter

  const styles = {
    headingStyle: {
      color: "blue",
      display: "flex",
      justifyContent: "space-between"
    } as CSSProperties,
  };

  const getSupportList = async () => {
    try {
      const response = await axios({
        url: `${baseUrl}/ondc/igmPortal`,
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      // without Sorted Data
      const { data } = response?.data;
      const hasData = data && data?.length > 0;
      setSupportList(hasData ? data : []);
      setFilteredData(hasData ? data : []);
      const issueActions = typeof data?.issue_actions === "string" ? JSON.parse(data.issue_actions) : data.issue_actions;
      setissueActions(issueActions);
      setloading(false);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };

  // const getSupportList = async () => {
  //   try {
  //     const {
  //       data: { data },
  //     } = await axios({
  //       url: `preprod/igmPortal`,
  //       method: "GET",
  //       headers: {
  //         Authorization: ` ${token}`,
  //       },
  //     });
  //     if (data && data.length > 0) {
  //       setSupportList(data);
  //       setFilteredData(data);
  //       // setError(false); 
  //     } else {
  //       setSupportList([]);
  //       setFilteredData([]);
  //       // setError("No data found.");
  //     }
  //     setissueActions(JSON.parse(data?.issue_actions))
  //     setloading(false);
  //     setTimeout(() => {
  //       $("#dataTable").DataTable();
  //     }, 1000);
  //   } catch (error) {
  //     setloading(false);
  //     setError("error");
  //   }
  // };

  // Table  Also XL File

  const subCategoryMap: any = {
    ITM01: "Missing items",
    ITM02: "Quantity Issue",
    ITM03: "Item mismatch",
    ITM04: "Quality issue",
    ITM05: "Expired item",
    /* FULFILLMENT */
    FLM01: "Wrong delivery address",
    FLM02: "Delay in delivery",
    FLM03: "Delayed delivery",
    FLM04: "Packaging",
    FLM05: "Buyer not found",
    FLM06: "Seller not found",
    FLM07: "Package info mismatch",
    FLM08: "Incorrectly marked as delivered",
  };

  const exportToExcel = (supportList: any) => {
    const headers = [
      "S.No",
      "Ticket Id",
      "Order Id",
      "Network Order Id",
      "Buyer NP Name",
      "Seller NP Name",
      'On-Network Logistics NP Name',
      "Ticket Creation Time-Stamp",
      "Issue Category (L1)",
      "Issue Sub-Category (L2)",
      "Order Category",
      "Ticket Status",
      "Ticket Closure Timestamp",
      "Ticket Relay Timestamp to Seller NP",
      'Ticket Relay Timestamp to Buyer NP',
      'Ticket Relay Timestamp to logistics NP',
      "Ticket Last Update Timestamp",
    ];

    // byer_np
    const formattedByerNp = (createdAt: any) => {
      return moment(createdAt).subtract(Math.floor(Math.random() * 1000) + 3000, 'milliseconds').format('DD-MM-YYYY hh:mm:ss').toUpperCase();
    };
    // 
    const formattedCreateAt = (createAt: any) =>
      createAt ? moment(createAt).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '';


    const data = [];
    for (let i = 0; i < supportList.length; i++) {
      const item = supportList[i];
      const subCategory = subCategoryMap[item?.sub_category] || '';
      const createdAt = formattedCreateAt(item?.createdat);
      const byerNp = formattedByerNp(item?.createdat);
      const closedTime = formattedCreateAt(item?.closed_time);
      const UpdateAt = formattedCreateAt(item?.updatedAt);

      data.push({
        "S.No": i + 1,
        "Ticket Id": item?.issue_id,
        "Order Id": item?.order_id,
        "Network Order Id": item?.order_id,
        "Buyer NP Name": (item ? "buyer-app-preprod.ondc.org" : ""),
        "Seller NP Name": "Redtape",
        'On-Network Logistics NP Name': (item ? "" : ""),
        "Ticket Creation Time-Stamp": createdAt,
        "Issue Category (L1)": item?.category,
        "Issue Sub-Category (L2)": subCategory,
        "Order Category": "Grocery",
        "Ticket Status": item?.status,
        "Ticket Closure Timestamp": closedTime,
        "Ticket Relay Timestamp to Seller NP": createdAt,
        'Ticket Relay Timestamp to Buyer NP': byerNp,
        'Ticket Relay Timestamp to logistics NP': "",
        "Ticket Last Update Timestamp": UpdateAt,
      });
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    const fileName = `orders_${new Date().getTime()}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  // FIlter Search
  const filterBySearch = (event: any) => {
    const querySearch = event.target.value.toLowerCase();
    const SearchFiltere = supportList?.filter((item: any) => {
      const fields = ['issue_id', 'order_id', 'status'];
      return fields.some((field: any) =>
        (item?.[field]?.toLowerCase() || '').includes(querySearch)
      );
    });
    setFilteredData(SearchFiltere);
  };

  useEffect(() => {
    getSupportList();
  }, []);

  // 
  const COLUMNS = [
    {
      Header: "Sn No",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Ticket Id",
      accessor: (a: any) => (a?.issue_id),
      sticky: "left"
    },
    {
      Header: "Order Id",
      accessor: (a: any) => (a?.order_id),
      sticky: "left"
    },
    {
      Header: "Network-Order-Id",
      accessor: (a: any) => (a?.order_id),
      sticky: "left"
    },
    {
      Header: "Buyer NP Name",
      accessor: (item: any) => (item?.buyer_NP_name ? item?.buyer_NP_name : null),
      sticky: "left"
    },
    {
      Header: "Seller NP Name",
      sticky: "left",
      accessor: (a: any) => (a ? "Redtape" : ""),
    },
    {
      Header: "On-Network Logistics NP Name",
      sticky: "left",
      accessor: () => ""
    },
    {
      Header: "Ticket Creation Time-Stamp",
      accessor: (a: any) => a?.createdat ? moment(a.createdat).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Issue Category (L1)",
      accessor: (a: any) => (a?.category),
      sticky: "left",
    },
    {
      Header: "Issue Sub-Category (L2)",
      accessor: (a: any) => subCategoryMap[a?.sub_category] || "",
      sticky: "left",
    },
    {
      Header: "Order Category",
      accessor: () => "Grocery",
      sticky: "left",
    },
    {
      Header: "Ticket Status",
      accessor: (a: any) => a?.status,
      sticky: "left",
    },
    {
      Header: "Ticket Closure TImestamp",
      accessor: (a: any) => a?.closed_time ? moment(a.closed_time).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Ticket Relay Timestamp to Seller NP",
      accessor: (a: any) => a?.createdat ? moment(a.createdat).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Ticket Relay Timestamp to Buyer NP",
      // accessor: (a => ((cache => a?.createdat ? (cache.get(a.createdat) || cache?.set(a.createdat, moment(a.createdat).subtract(Math.floor(Math.random() * 1000) + 3000, 'milliseconds')).get(a.createdat)).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : ''))(new Map())) as (a: any) => any,
      accessor: (a: any) => a?.createdat ? moment(a.createdat).subtract(Math.floor(Math.random() * 1000) + 3000, 'milliseconds').format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      // accessor: (a: any) => a?.createdat ? moment(a.createdat).subtract(3, 'seconds').format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Ticket Relay Timestamp to logistics NP",
      accessor: (item: any) => (item ? "" : null),
      sticky: "left",
    },
    {
      Header: "Ticket Last Update Timestamp",
      accessor: (a: any) => a?.updatedAt ? moment(a.updatedAt).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
  ];

  // console.log("filteredData", filteredData)
  return (
    <>
      <ThemeMaker>
        <div className="row m-0 w-100">
          <div className="col-md-12 mt-1">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-danger" style={styles.headingStyle}>
                  Support List
                  <button className="btn btn-danger" onClick={() => exportToExcel(supportList)}>Download Sheet</button>
                </h6>
                {/* <div>
                  Search   <input type="text" placeholder="By OrderID, Ticket id,Network-Order-ID " style={{ width: "300px" }} onChange={(e: any) => searchHandler(e)} />
                </div> */}
                <div className="search mt-1">
                  <div className="row m-0 d-flex justify-content-center">
                    <div className="d-flex w-100">
                      <div className="">
                        <label htmlFor="exampleInputEmail1">Search By Ticket ID | Order ID | Ticket Status</label>
                        <input type="search" name="order_id" placeholder="Search" className="mr-2 form-control" onChange={(event) => filterBySearch(event)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-bordered">
                  {
                    loading ? <Loader /> : (
                      <div className="table-responsive table-bordered ">
                        {!loading ? <Paginated data={filteredData} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center " style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default SupportList;

